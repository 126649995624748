@charset "UTF-8";
[data-v-3cd071c3] .robot {
  margin-right: 6px;
}
.search-content[data-v-3cd071c3] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #ffffff;
}
.search-content .search-input[data-v-3cd071c3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.search-content .search-input .el-input[data-v-3cd071c3] {
  width: 420px;
}
.search-content .search-input .el-button[data-v-3cd071c3] {
  cursor: pointer;
  height: 40px;
  border-radius: 0 4px 4px 0;
}
.search-content .search-input .history-dialog[data-v-3cd071c3] {
  width: 462px;
  position: absolute;
  top: 40px;
  left: calc(50% - 307px);
  padding: 12px;
  background: #fff;
  z-index: 999;
  border-left: 1px solid #DCDFE6;
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
}
.search-content .search-input .history-dialog .history-content[data-v-3cd071c3] {
  line-height: 30px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 12px;
}
.search-content .search-input .history-dialog .history-content[data-v-3cd071c3]:hover {
  background: #F5F7FA;
}
.search-content .search-input .history-dialog .history-content span[data-v-3cd071c3] {
  font-size: 14px;
  color: #606266;
  padding-left: 12px;
}
.search-content .search-input .history-dialog .btns[data-v-3cd071c3] {
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-size: 14px;
}
.search-content .search-input .history-dialog .btns .dialog-btn[data-v-3cd071c3] {
  width: 90px;
  height: 30px;
  background: #1890ff;
  color: #fff;
  border: 0;
  font-size: 14px;
  border-radius: 3px;
}
.search-content .typeBtnBox[data-v-3cd071c3] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
}
.search-content .main-content[data-v-3cd071c3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  height: calc(100% - 205px);
}
.search-content .main-content .el-tabs[data-v-3cd071c3] {
  width: 80%;
  border: 1px solid #ccc;
}
.search-content .main-content .el-tabs[data-v-3cd071c3] .el-tabs__header {
  padding-left: 12px;
}
.search-content .main-content .el-tabs[data-v-3cd071c3] .el-tabs__content {
  padding: 0 !important;
}
.search-content .main-content .main-right[data-v-3cd071c3] {
  width: calc(20% - 15px);
  margin-left: 10px;
  border: 1px solid #ccc;
  position: relative;
}
.search-content .main-content .main-right .right-head[data-v-3cd071c3] {
  height: 100%;
  text-align: center;
  width: 100%;
  font-size: 14px;
}
.search-content .main-content .main-right .right-head[data-v-3cd071c3] .el-tabs__content {
  padding: 0 !important;
  background-color: #fff !important;
  height: calc(100% - 55px);
}
.search-content .main-content .main-right .right-head[data-v-3cd071c3] .el-tabs__content .el-tab-pane {
  height: 100%;
}
.search-content .main-content .main-right .hot-content[data-v-3cd071c3] {
  list-style: none;
  overflow-y: auto;
  padding: 0 12px;
  margin: 0;
  height: 100%;
  overflow: auto;
}
.search-content .main-content .main-right .hot-content li[data-v-3cd071c3] {
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  color: #606266;
  overflow-x: hidden; /* 隐藏超出li的文本 */
  white-space: nowrap; /* 防止文本换行 */
  text-overflow: ellipsis; /* 超出部分显示为... */
}
.search-content .main-content .main-right .hot-content .hot-icon[data-v-3cd071c3] {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
.search-content .main-content .main-right .more-btn[data-v-3cd071c3] {
  position: absolute;
  top: 5px;
  right: 0;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.search-content .main-content .main-right .more-btn span[data-v-3cd071c3] {
  margin-right: 10px;
  text-decoration: underline;
}
[data-v-3cd071c3] .el-input--medium .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px 0 0 4px;
}
[data-v-3cd071c3] .knowledgeSearchGrid .el-table {
  height: 520px !important;
  max-height: 520px !important;
}